// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-blank-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/src/pages/blank.js" /* webpackChunkName: "component---src-pages-blank-js" */),
  "component---src-pages-hire-us-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/src/pages/hire-us.js" /* webpackChunkName: "component---src-pages-hire-us-js" */),
  "component---src-pages-index-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plasmademo-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/src/pages/plasmademo.js" /* webpackChunkName: "component---src-pages-plasmademo-js" */),
  "component---src-pages-projects-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-work-js": () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import("/Users/jpb/code/wip/elixir/madvoid.com/frontend-gatsby/.cache/data.json")

